.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.video-player-parent {
    text-align: center;
}

.video-player {
    display: inline-block;
}

.video-upload {}

.video-upload .progress {
    height: 20px;
    background-color: darkcyan;
    margin-top: 10px;
}

.video-upload .progress .progress-bar {
    height: 20px;
    background-color: chartreuse;
}

.video-upload .progress .progress-message {
    font-size: 20px;
    color: green;
    margin-bottom: 10px;
    text-align: center;
}

.video-upload .action-buttons {
    padding: 10px 0px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.MuiOutlinedInput-multiline {
    min-height: 70px;
}

.MuiOutlinedInput-input {
    padding: 14.5px 14px;
}

.MuiOutlinedInput-multiline textarea {
    min-height: 70px;
}

.MyTitleCase {
    text-transform: capitalize;
}

.timeline-container {
    margin-top: 30px;
}

.subject-prompt-container {
    font-size: 1.05rem;
}

.my-label {
    margin-right: 10px;
    ;
}

.my-select {
    font-size: 1.05rem;
    width: 100%;
    padding: 5px;
}

.topic-title {
    font-size: 1.2rem;
}

.topic-note {
    font-size: 0.8rem;
}

.topic-time-stamp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.resource-type {
    margin-bottom: 10px;
    font-style: oblique;
}

.link {
    cursor: pointer;
    font-style: 1.5rem;
}

div.link>a {
    text-decoration: none;
}

.video-player-backdrop {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 9991;
    background-color: rgba(80, 80, 80, 0.9);
}

.video-player-container-in-timeline {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin: 0px;
    z-index: 9990;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-player-container {
    position: absolute;
    margin: 0px auto;
    vertical-align: middle;
    text-align: center;
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
}

.progress-bar-parent {
    position: relative;
    width: 100%;
    height: 25px;
    background-color: #327345;
    margin-top: 10px;
    margin-bottom: 10px;
}

.progress-bar {
    width: 0%;
    height: 25px;
    background-color: #baebae;
    text-align: center;
}

.progress-bar-percent {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 25px;
    color: black;
    line-height: 25px;
}

.numberCircle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 4px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 16px Arial, sans-serif;
}

.assignment-code-div {
    margin-top: 5px;
    ;
}

.assignment-details {
    margin: 10px 0px;
}

.layout .MuiButton-containedSecondary {
    color: #fff;
    background-color: rgb(220, 0, 78);
}

.layout .MuiButton-containedSecondary:hover {
    background-color: rgb(154, 0, 54);
}

.org-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo-container {
    text-align: center;
    margin: 20px;
}

.org-name {
    font-size: 26px;
    color: #1f489f;
    font-weight: bolder;
}

.org-address {
    font-size: 18px;
    color: #1f489f;
}

.view-resource {}

.logo-container {
    height: 101px;
}

.logo-container img {
    height: 101px;
    width: auto;
}

.timeline-container .vertical-timeline--two-columns .vertical-timeline-element-content {
    padding-bottom: 10px;
}

.mark-read-container {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}

.logo-name {
    margin: 0px;
    color: #fff;
    font-size: 18px;
}

.registerCard {
    min-width: 300px;
    margin-top: '0em';
    height: 380px;
    background-color: "whitesmoke";
    display: 'flex';
    justify-content: "center";
    border-radius: "0px 5px 5px 0px";
}

.login-card {
    height: 380px;
    min-width: 300px;
    margin-top: 0em;
    border-radius: 5px 0px 0px 5px;
}

.layout .MuiButton-root {
    line-height: 1.2;
}

@media only screen and (max-width: 600px) {
    .registerCard {
        display: none;
    }

    .login-card {
        min-width: 90%;
    }

    .logo-name {
        font-size: 10px;
    }

    .MyTitleCase {
        font-size: 10px;
    }
}

.stop-meeting {
    margin: 0px 10px !important;
}

.live-class-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    flex-direction: column;
    position: relative;
}

.video-conference-parent {
    text-align: center;
}

#easysoft-vc-container {
    width: 100%;
    height: 650px;
    margin-bottom: 20px;
}

.vc-actions {
    display: flex;
    flex-direction: row;
}

#account_id-helper-text {
    display: none;
}

.video-player-frame {
    border: 0px;
}

.close-me-btn {
    margin-top: 15px !important;
}

.video-player {
    border: 0px;
}

.meeting-progress {
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.list-page .MuiTableCell-sizeSmall {
    padding: 6px 6px 6px 6px;
    vertical-align: top;
}
.column-description > span{
    max-height: 88px;
    overflow: hidden;
    display: block;
}
.change-password-result {
    text-align: center;
    margin: 20px;
    color: #f00;
    font-weight: bold;
}

.after-hangup {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 9999;
    background-color: #fff;
}

.report-title {
    text-align: center;
    font-size: large;
    font-weight: bold;
    margin: 10px;
    padding-top: 15px;
}

.dashboard-filters {
    text-align: center;
}

.action-btn {
    margin: 10px;
    text-align: center;
    padding-bottom: 15px;
}

.dashboard-filters .dropdown {
    margin: 0px 10px;
    height: 40px;
    min-width: 150px;
    font-size: 18px;
    padding: 0px 15px;
}

.dashboard-options {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #999;
    padding-bottom: 10px;
}

.dashboard-options>div {
    padding: 10px 20px;
    background-color: #3f51b5;
    color: #fff;
    margin: 0px 5px;
    cursor: pointer;
    border-bottom: 4px solid #3f51b5;
    text-transform: uppercase;
}

.dashboard-options>div.selected {
    border-bottom: 4px solid #aa0000;
}

.loading {
    text-align: center;
    padding: 20px;
}

.no-records {
    text-align: center;
    padding: 20px;
}

.card-panel-header {
    background-color: #43569b !important;
    color: #fff !important;
}

.easysoft-technologies-logo {
    position: absolute;
    top: 38px;
    background-color: #5ea9e5;
    width: 141px;
    height: 53px;
    z-index: 9999;
    left: 33px
}

.easysoft-technologies-logo img {
    width: auto;
    height: 100%;
}


.first_inline_input {
    display: inline-block;
    width: 48%;
}

.last_inline_input {
    display: inline-block;
    margin-left: 4%;
    width: 48%;
}

.one_three_input {
    display: inline-block;
    width: 30%;
}

.two_three_input {
    display: inline-block;
    margin-left: 5%;
    width: 30%;
}

.last_three_input {
    display: inline-block;
    margin-left: 5%;
    width: 30%;
}

.time-input-field .MuiOutlinedInput-input {
    padding: 10.5px 14px;
}

.time-input-field {
    margin-bottom: 15px !important;
    margin-top: 8px !important;
}

.uploaded-image {
    max-width: 400px;
}

.quiz-details-image {
    max-width: 600px;
}

.start-question-actions {
    display: flex;
}

.item-parent .name .student-name {
    font-size: 18px;
}

.item-parent .name .city-name {
    font-size: 12px;
}

@media only screen and (max-width: 910px) {
    .first_inline_input {
        display: inline-block;
        width: 100%;
    }

    .last_inline_input {
        display: inline-block;
        margin-left: 0%;
        width: 100%;
    }

    .one_three_input {
        display: inline-block;
        width: 100%;
    }

    .two_three_input {
        display: inline-block;
        margin-left: 0%;
        width: 100%;
    }

    .last_three_input {
        display: inline-block;
        margin-left: 0%;
        width: 100%;
    }
}

.login-password-field {
    position: relative
}

.password-eye {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 15px 10px;
    cursor: pointer;
}

.uploaded-files {
    margin: 10px 0px;
}

.file-name {
    margin-top: 10px;
}

.uploaded-files-caption {
    font-weight: bold;
    margin-bottom: 10px;
}

._react_fileupload_form_content input[type=submit] {
    margin-top: 10px;
    padding: 5px 20px;
}

.intro-modal-title {
    text-align: center
}

.video-model {
    width: 640px;
    position: absolute;
    background-color: #fff;
}

.intro-video-player {
    width: 640px;
    height: 480px;
    border: 0px;
}

.hint {
    font-size: 12px;
    color: #2a48cf;
    margin-bottom: 5px;
}

.close-button {
    margin-top: 20px;
}

.assignment-details {
    width: 100% !important;
}

.submit-bid {
    cursor: pointer;

}

.quote-actions {
    display: flex;
    justify-content: center;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 156px);
}

h1.logo-text {
    font-size: 20px
}
.team-item{
    border: 1px #FF5E14 solid;
}

.team-item .img-fluid {
    height: 300px;
    object-fit: cover;
    width: 360px;
}

@media only screen and (max-width: 600px) {}